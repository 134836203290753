import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/vercel/path0/apps/sergei20/src/components/LayoutInner/LayoutInner.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Если вы попали на этот сайт, знайте: это приложеньице для проработки ментальных блоков мною, Сергеем Ганноченко, после просмотра курса `}<a parentName="p" {...{
        "href": "https://gc.gorodinvestorov.ru/millioner"
      }}>{`"Действуй как миллионер"`}</a>{`!`}</p>
    <p>{`Оно так-то должно было быть закрыто от индексирования поисковичками, но раз уж вы как-то сюда попали, то, как говорится, "вэлкам"!`}</p>
    <p>{`Можете, кстати, мне `}<a parentName="p" {...{
        "href": "https://www.linkedin.com/in/gannochenko/"
      }}>{`что-нибудь написать`}</a>{`!`}</p>
    <p>{`Или заценить `}<a parentName="p" {...{
        "href": "https://gannochenko.dev/"
      }}>{`мой блог`}</a>{` :)`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      